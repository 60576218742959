import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  radius,
  shadows
} from '@proprioo/salatim';

export const BLOCK_DEFAULT = 350;
export const BLOCK_XSMALL = 300;
export const BLOCK_BASE = 224;

export const Layout = styled.div<{ isPrimary: boolean; picture: string }>`
  overflow: hidden;
  position: relative;
  box-shadow: ${shadows.level5};
  border-radius: ${radius.big};
  border: ${convertPxToRem(1)} solid ${colors.dark.base};
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: ${BLOCK_BASE}px;

  h4 {
    color: inherit;
  }

  & + & {
    margin-top: ${convertPxToRem(8)};
  }

  ${({ isPrimary }) =>
    isPrimary &&
    css`
      color: ${colors.dark.base};
    `}

  @media (min-width: ${breakpoints.base}px) {
    flex-direction: row;
  }
`;

export const BlockLayout = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;

  h4 {
    flex: 1;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  max-height: ${convertPxToRem(166)};

  @media (min-width: ${breakpoints.base}px) {
    height: unset;
    max-height: unset;
    width: 50%;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: top center;

  @media (min-width: ${breakpoints.base}px) {
    object-position: bottom center;
  }
`;

export const BlockText = styled.div<{ isPrimary: boolean }>`
  z-index: 1;
  position: relative;
  padding: ${convertPxToRem(16)} ${convertPxToRem(16)} 0;
  background: ${colors.dark.base60};
  width: 100%;

  ${({ isPrimary }) =>
    isPrimary &&
    css`
      background-color: white;
    `}

  ::before {
    content: '';
    position: absolute;
    bottom: -${convertPxToRem(50)};
    left: -${convertPxToRem(50)};
    right: -${convertPxToRem(50)};
    height: ${convertPxToRem(100)};
    background: inherit;
    border-radius: ${radius.rounded};
    z-index: 1;

    @media (min-width: ${breakpoints.base}px) {
      bottom: -${convertPxToRem(50)};
      left: unset;
      right: -${convertPxToRem(50)};
      top: -${convertPxToRem(50)};
      width: ${convertPxToRem(100)};
      height: unset;
    }
  }

  @media (min-width: ${breakpoints.xsmall}px) {
    padding: ${convertPxToRem(24)} ${convertPxToRem(24)} 0;
    flex: 1;
  }

  @media (min-width: ${breakpoints.base}px) {
    flex: unset;
    padding: ${convertPxToRem(32)} 0 ${convertPxToRem(24)} ${convertPxToRem(32)};
  }
`;

export const Text = styled.p`
  @media (min-width: ${breakpoints.base}px) {
    margin-bottom: ${convertPxToRem(16)};
  }
`;

export const ButtonsLayout = styled.div`
  margin-top: ${convertPxToRem(18)};
  display: flex;
  flex-direction: column;

  a + a {
    display: inline-block;
    margin-top: ${convertPxToRem(8)};
  }

  @media (min-width: ${breakpoints.xsmall}px) {
    flex-direction: row;

    a + a {
      margin-top: 0;
      margin-left: ${convertPxToRem(8)};
    }
  }
`;

export const CallToActionAlone = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  background: white;

  ${Text} {
    @media (min-width: ${breakpoints.base}px) {
      margin-bottom: 0;
    }
  }

  ${ImageWrapper} {
    @media (min-width: ${breakpoints.base}px) {
      width: 70%;
    }
  }

  ${Image} {
    @media (min-width: ${breakpoints.base}px) {
      width: 100%;
      min-height: ${convertPxToRem(230)};
      max-height: ${convertPxToRem(250)};
    }
  }
`;
