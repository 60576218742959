import Router from 'next/router';
import { transparentize } from 'polished';
import { Elements, RichTextBlock } from 'prismic-reactjs';
import { createElement } from 'react';

import { URLS } from '@/constants/global';
import { TITLE_DATALAYER } from '@/utils/gtm';
import {
  ArticleBodyElement,
  ArticlePrimaryHow,
  ArticlePrimaryParagraph,
  ArticlePrimaryQuote,
  hrefResolver,
  linkResolver,
  PrismicAgent,
  PrismicArticle,
  PrismicChapter,
  PrismicCover,
  PrismicFaq,
  PrismicHub,
  PrismicLink,
  PrismicPage,
  TypeSlice
} from '@/utils/prismic';
import {
  FilledContentRelationshipField,
  PrismicDocument
} from '@prismicio/client';
import {
  ButtonAppearance,
  ButtonTheme,
  colors,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6
} from '@proprioo/salatim';

import CustomLinkStyled from '../common/customLink/CustomLinkStyled';
import {
  CustomHyperlink,
  CustomList,
  CustomListItem,
  CustomOrderedList,
  CustomOrderedListItem,
  CustomParagraph,
  EmbedWrapper
} from './article/Article.styles';

export enum TypeGuide {
  HUB = 'guide_hub',
  CHAPTER = 'guide_chapitre',
  ARTICLE = 'guide_article',
  PREVIOUS = 'guide'
}

export enum TypeCta {
  BOOKING = 'ctaBooking',
  JOIN = 'ctaJoin',
  VALUATION = 'ctaValuation'
}

export enum CtaGuideType {
  BUTTON = 'button',
  CARD = 'card'
}

export enum TypeHeaderImgDisplay {
  FULL = 'Pleine largeur',
  RIGHT = 'Ferré à droite'
}

export enum HeaderColors {
  GREEN = 'Vert',
  BLUE = 'Bleu'
}

export type ChapterPathProps = {
  params: {
    chapter: string;
    hub: string;
  };
};

export type CardStyles = {
  backgroundColor: string;
  buttonAppearance: ButtonAppearance;
  buttonTheme: ButtonTheme;
  titleColor: string;
  textColor: string;
  svgColor: string;
};

export type ArticlePathProps = ChapterPathProps & {
  params: ChapterPathProps['params'] & {
    article: string;
  };
};

export type SameArticle = {
  article_link: {
    id: string;
  };
};

export type Cta = {
  cta: TypeCta;
};

export type Slice = {
  type: TypeSlice;
  content: RichTextBlock[];
};

export type ParagraphArticle = Slice & {
  cta: TypeCta;
};

export type QuoteArticle = Slice & {
  agent: string;
};

export type HowArticle = Slice & {
  title: RichTextBlock[];
};

export type CtaArticle = Slice & {
  buttonTitle: string;
  buttonUrl: PrismicLink;
  cardTitle: RichTextBlock[];
  customType: CtaGuideType;
  theme: ButtonTheme;
  defaultCta?: TypeCta;
};

type ItemsArticle =
  | Slice
  | ParagraphArticle
  | QuoteArticle
  | HowArticle
  | CtaArticle;

export type StandardProps = {
  id: string;
  title: RichTextBlock[];
  slug: string;
  metaDescription?: string;
  metaTitle?: string;
};

export type PageProps = StandardProps & {
  color: string;
};

export type SimpleArticleProps = StandardProps & {
  cover: PrismicCover;
  items: ItemsArticle[];
};

export type SimpleChapterProps = StandardProps & {
  color: string;
  content?: RichTextBlock[];
  ctas?: TypeCta[];
};

export type SimpleHubProps = StandardProps & {
  content?: RichTextBlock[];
  ctas?: TypeCta[];
};

export type ArticleProps = {
  article: SimpleArticleProps;
  sameArticles: SimpleArticleProps[];
  chapter: SimpleChapterProps;
  hubs: SimpleHubProps[];
};

export type ChapterProps = {
  articles: ArticleProps[];
  chapter: SimpleChapterProps;
  hubs: SimpleHubProps[];
};

export type ChapterWithArticlesProps = SimpleChapterProps & {
  articles: SimpleArticleProps[];
};

export type HubProps = {
  chapters: ChapterWithArticlesProps[];
  hub: SimpleHubProps;
};

export type ResponsePageProps = PrismicDocument & { data: PrismicPage };
export type ResponseHubProps = PrismicDocument & { data: PrismicHub };
export type ResponseChapterProps = PrismicDocument & { data: PrismicChapter };
export type ResponseArticleProps = PrismicDocument & { data: PrismicArticle };
export type ResponseFaqProps = PrismicDocument & { data: PrismicFaq };
export type ResponseAgentProps = PrismicDocument & {
  data: PrismicAgent;
};

export const ARTICLES_ORDERING = {
  field: `my.${TypeGuide.ARTICLE}.scoring, document.last_publication_date desc`
};

export const getArticleIds = (articles: SameArticle[]): string[] =>
  articles
    .map(({ article_link }: SameArticle) => article_link && article_link.id)
    .filter(Boolean);

export const buildSimpleChapterFromPrismic = ({
  data,
  id,
  uid
}: ResponseChapterProps): SimpleChapterProps => {
  const { color, title, ctas, meta_description, meta_title } = data;

  return {
    id,
    slug: `${uid}`,
    title,
    color,
    ...(meta_title && { metaTitle: meta_title }),
    ...(meta_description && {
      metaDescription: meta_description
    }),
    ...(ctas && { ctas: ctas.map(({ cta }) => cta) })
  };
};

export const buildSimpleHubFromPrismic = ({
  data,
  id,
  uid
}: ResponseHubProps): SimpleHubProps => {
  const { ctas, title, meta_description, meta_title } = data;

  return {
    id,
    slug: `${uid}`,
    title,
    ...(meta_title && { metaTitle: meta_title }),
    ...(meta_description && {
      metaDescription: meta_description
    }),
    ...(ctas && { ctas: ctas.map(({ cta }) => cta) })
  };
};

export const buildSimpleArticleFromPrismic = ({
  data,
  uid,
  id
}: ResponseArticleProps): SimpleArticleProps => {
  const { cover, title, meta_description, meta_title } = data;

  // Check & fill alt' cover
  if (cover.url) {
    cover.alt = cover.mobile.alt && !cover.alt ? cover.mobile.alt : '';
    cover.mobile.alt = !cover.mobile.alt && cover.alt ? cover.alt : '';
  }

  return {
    id,
    title,
    slug: `${uid}`,
    cover,
    ...(meta_title && { metaTitle: meta_title }),
    ...(meta_description && {
      metaDescription: meta_description
    }),
    items: buildItemsFromArticle(data.body)
  };
};

export const buildArticleFromPrismic = (
  document: ResponseArticleProps,
  sameArticlesPrismic?: ResponseArticleProps[]
): ArticleProps => {
  const chapterLink = document.data.chapter_link;
  const hubs = chapterLink.data.hubs;
  const sameArticles = sameArticlesPrismic?.map(result =>
    buildSimpleArticleFromPrismic(result)
  );

  return {
    article: buildSimpleArticleFromPrismic(document),
    sameArticles: sameArticles || [],
    chapter: buildSimpleChapterFromPrismic(chapterLink),
    hubs: hubs.map(hub => buildSimpleHubFromPrismic(hub.hub_link))
  };
};

export const buildChapterFromPrismic = (
  document: ResponseChapterProps,
  articles: ResponseArticleProps[]
): ChapterProps => ({
  chapter: buildSimpleChapterFromPrismic(document),
  hubs: document.data.hubs.map(hub => buildSimpleHubFromPrismic(hub.hub_link)),
  articles: articles.map(result => buildArticleFromPrismic(result))
});

export type PrismicChapterWithArticle = ResponseChapterProps & {
  articles: ResponseArticleProps[];
};

export const buildHubFromPrismic = (
  document: ResponseHubProps,
  chapters: PrismicChapterWithArticle[]
): HubProps => {
  const chaptersInHub = chapters.map(chapter => ({
    ...buildSimpleChapterFromPrismic(chapter),
    articles:
      chapter.articles.map(article => buildSimpleArticleFromPrismic(article)) ||
      []
  }));

  return {
    hub: buildSimpleHubFromPrismic(document),
    chapters: chaptersInHub || []
  };
};

export const buildItemsFromArticle = (
  body: ArticleBodyElement[] = []
): ItemsArticle[] =>
  body.reduce((acc: ItemsArticle[], item) => {
    const { slice_type } = item;
    const primaryParagraph = item.primary as ArticlePrimaryParagraph;
    const primaryQuote = item.primary as ArticlePrimaryQuote;
    const primaryHow = item.primary as ArticlePrimaryHow;

    switch (slice_type) {
      case TypeSlice.PARAGRAPH:
        acc.push({
          type: slice_type,
          content: primaryParagraph.content,
          cta: primaryParagraph.cta
        });
        break;
      case TypeSlice.QUOTE:
        acc.push({
          type: slice_type,
          content: primaryQuote.content,
          agent: primaryQuote.agent
        });
        break;
      case TypeSlice.HOW:
        acc.push({
          type: slice_type,
          content: primaryHow.content,
          title: primaryHow.title_how
        });
        break;
      case TypeSlice.CTA:
        acc.push({
          ...item.primary,
          type: slice_type
        });
        break;
      default:
        acc.push({
          type: slice_type,
          content: item.primary.content
        });
        break;
    }
    return acc;
  }, []);

export const buildGuidePath = (
  hub: string,
  chapter?: string,
  article?: string
) => `${URLS.GUIDE.href}/${[hub, chapter, article].filter(Boolean).join('/')}`;

export const buildPath = (hub: string, chapter?: string, article?: string) =>
  `${TITLE_DATALAYER.GUIDE}-${[hub, chapter, article]
    .filter(Boolean)
    .join('_')}`;

export const generateLink = (data: FilledContentRelationshipField) => ({
  label: data.uid,
  isInternal: data.type !== TypeGuide.PREVIOUS,
  as: linkResolver(data),
  href: hrefResolver(data)
});

const onClickHandler = (href: string) => (e: Event) => {
  e.preventDefault();
  Router.push(href);
};

// -- Function to add unique key to props
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const propsWithUniqueKey = (props: any, key: any) => ({
  ...(props || {}),
  key
});

// -- HTML Serializer
export const htmlSerializer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: any
) => {
  let props = {};

  const embedHtml = createElement(EmbedWrapper, {
    dangerouslySetInnerHTML: { __html: element?.oembed?.html }
  });

  let internal = false;
  if (element?.linkTo && element?.linkTo?.link_type === 'Document') {
    internal = true;
    props = {
      onClick: onClickHandler(hrefResolver(element.linkTo))
    };
  }
  const linkUrl = element.linkTo
    ? element.linkTo.url || linkResolver(element.linkTo)
    : null;
  const linkTarget =
    element.linkTo && element.linkTo.target
      ? { target: element.linkTo.target }
      : {};
  const linkRel = linkTarget.target ? { rel: 'noopener noreferrer' } : {};
  const img = createElement('img', {
    src: element.url,
    alt: element.alt || '',
    loading: 'lazy'
  });

  switch (type) {
    case Elements.heading1: // Heading 1
      return createElement(
        H1,
        propsWithUniqueKey({ ...props, title: element.text }, key),
        children
      );
    case Elements.heading2: // Heading 2
      return createElement(
        H2,
        propsWithUniqueKey({ ...props, title: element.text }, key),
        children
      );
    case Elements.heading3: // Heading 3
      return createElement(
        H3,
        propsWithUniqueKey({ ...props, title: element.text }, key),
        children
      );
    case Elements.heading4: // Heading 4
      return createElement(
        H4,
        propsWithUniqueKey({ ...props, title: element.text }, key),
        children
      );
    case Elements.heading5: // Heading 5
      return createElement(
        H5,
        propsWithUniqueKey({ ...props, title: element.text }, key),
        children
      );
    case Elements.heading6: // Heading 6
      return createElement(
        H6,
        propsWithUniqueKey({ ...props, title: element.text }, key),
        children
      );
    case Elements.paragraph: // Paragraph
      return createElement(
        CustomParagraph,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.strong: // Strong
      return createElement('strong', propsWithUniqueKey(props, key), children);
    case Elements.em: // Emphasis
      return createElement('em', propsWithUniqueKey(props, key), children);
    case Elements.listItem: // Unordered List Item
      return createElement(
        CustomListItem,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.oListItem: // Ordered List Item
      return createElement(
        CustomOrderedListItem,
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.list: // Unordered List
      return createElement(
        CustomList,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.oList: // Ordered List
      return createElement(
        CustomOrderedList,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.preformatted: // Preformatted
      return createElement('pre', propsWithUniqueKey(props, key), children);
    case Elements.embed: // Embed
      props = {
        'data-oembed': element.oembed.embed_url,
        'data-oembed-type': element.oembed.type,
        'data-oembed-provider': element.oembed.provider_name,
        ...(element.label ? { className: element.label } : {})
      };
      return createElement('div', propsWithUniqueKey(props, key), embedHtml);
    case Elements.hyperlink: // Link
      if (element.data.link_type === 'Document') {
        props = {
          link: generateLink(element.data)
        };
        return createElement(
          CustomLinkStyled,
          propsWithUniqueKey(props, key),
          children
        );
      } else {
        const targetAttr = element.data.target
          ? { target: element.data.target }
          : {};
        const relAttr = element.data.target
          ? { rel: 'noopener noreferrer' }
          : {};
        props = {
          href: element.data.url || linkResolver(element.data),
          ...targetAttr,
          ...relAttr
        };
        return createElement(
          CustomHyperlink,
          propsWithUniqueKey(props, key),
          children
        );
      }
    case Elements.image: // Image
      return createElement(
        'p',
        propsWithUniqueKey(
          { className: [element.label || '', 'block-img'].join(' ') },
          key
        ),
        linkUrl
          ? createElement(
              'a',
              // if it's an internal link, replace the onClick
              internal
                ? propsWithUniqueKey(props, key)
                : { href: linkUrl, ...linkTarget, ...linkRel },
              img
            )
          : img
      );
    case Elements.label: // Label
      props = element.data ? { className: element.data.label } : {};
      return createElement('span', propsWithUniqueKey(props, key), children);
    case Elements.span: // Span
      if (content) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return content.split('\n').reduce((acc: any, p: any) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = createElement('br', propsWithUniqueKey({}, brIndex));
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }

    default:
      // Always include a default that returns null
      return null;
  }
};

export const getCardStyles = (theme: ButtonTheme): CardStyles => {
  switch (theme) {
    case ButtonTheme.BLUE:
      return {
        backgroundColor: colors.blue.base80,
        buttonAppearance: ButtonAppearance.PRIMARY,
        buttonTheme: ButtonTheme.BLACK,
        titleColor: colors.dark.base,
        textColor: 'black',
        svgColor: colors.blue.base
      };
    case ButtonTheme.PINK:
    case ButtonTheme.ORANGE:
      return {
        backgroundColor: colors.red.base5,
        buttonAppearance: ButtonAppearance.PRIMARY,
        buttonTheme: ButtonTheme.ORANGE,
        titleColor: colors.terracota.base,
        textColor: 'black',
        svgColor: colors.red.base10
      };
    default:
      return {
        backgroundColor: colors.dark.base,
        buttonAppearance: ButtonAppearance.SECONDARY,
        buttonTheme: ButtonTheme.WHITE,
        titleColor: 'white',
        textColor: 'white',
        svgColor: transparentize(0.8, colors.dark.base)
      };
  }
};
