import { createGlobalStyle, css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  InterFont
} from '@proprioo/salatim';

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }
  html {
    scroll-behavior: auto;
  }
  body {
    ${InterFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${colors.background};
  }
  a {
    text-decoration: none;
  }
  #__next {
    min-height: 100%;
    height: 100%;    
  }
  h1, h2, h3, h4 {
    margin: 0;
    
    * {
      font-family: inherit;
    }
  }
`;

export const Grid = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: ${convertPxToRem(16)};

  @media (min-width: ${breakpoints.base}px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${convertPxToRem(32)};
  }
`;

export const GridChildMediaWrapper = css`
  position: relative;
  padding-top: 100%;
  margin: 0 -${convertPxToRem(16)};
  overflow: hidden;
  background-color: ${colors.grey.base10};

  @media (min-width: ${breakpoints.xsmall}px) {
    margin-left: -${convertPxToRem(24)};
    margin-right: -${convertPxToRem(24)};
  }

  @media (min-width: ${breakpoints.small}px) {
    margin-left: -${convertPxToRem(40)};
    margin-right: -${convertPxToRem(40)};
  }

  @media (min-width: ${breakpoints.medium}px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Image = css`
  > div {
    height: 100%;
    padding: 0;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
