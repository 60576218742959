import styled from 'styled-components';

import { colors, paragraphs } from '@proprioo/salatim';

export const Layout = styled.span`
  a {
    ${paragraphs.body1}
    font-weight: bold;
    color: ${colors.terracota.base80};
  }
`;
