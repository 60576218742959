import Link from 'next/link';
import { Link as LinkHelper } from 'prismic-reactjs';
import React, { FC, PropsWithChildren } from 'react';

import { UrlProps } from '@/constants/global';
import { hrefResolver, linkResolver, PrismicLink } from '@/utils/prismic';

export enum TargetLink {
  BLANK = '_blank'
}

export type CustomLinkProps = {
  link: UrlProps;
  dataTest?: string;
  id?: string;
  shallow?: boolean;
  target?: TargetLink;
};

export const formatId = (label: string) =>
  label?.replace(/\s/g, '-').toLowerCase();

/*
 * Build custom link props from a prismicLink + label
 * */
export const getCustomLinkProps = (
  pLink: PrismicLink,
  label = ''
): CustomLinkProps => {
  const docLink = { ...pLink, tags: [] };
  const as = LinkHelper.url(docLink, linkResolver);
  const href = LinkHelper.url(docLink, hrefResolver);

  const link: UrlProps = {
    label,
    href,
    as,
    isInternal: docLink.link_type === 'Document'
  };

  return {
    link,
    id: docLink.id,
    ...(docLink.target === TargetLink.BLANK && { target: TargetLink.BLANK })
  };
};

const CustomLink: FC<PropsWithChildren<CustomLinkProps>> = ({
  link,
  dataTest,
  id,
  shallow,
  target,
  children
}) => {
  const { href, as, isInternal, label } = link;
  const formatedId = id ? formatId(id) : `link-custom-${formatId(label)}`;
  const linkRel = target === TargetLink.BLANK ? { rel: 'noopener' } : {};

  return isInternal ? (
    <Link
      as={as}
      aria-label={label}
      data-test={dataTest}
      href={href}
      id={formatedId}
      shallow={shallow}
      target={target}
    >
      {children}
    </Link>
  ) : (
    <a
      aria-label={label}
      data-test={dataTest}
      href={href}
      id={formatedId}
      target={target}
      {...linkRel}
    >
      {children}
    </a>
  );
};

export default CustomLink;
