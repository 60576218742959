import { RichTextBlock } from 'prismic-reactjs';

import {
  Cta,
  CtaGuideType,
  TypeCta,
  TypeGuide
} from '@/components/guide/utils';
import { URL_COMPANY, URLS } from '@/constants/global';
import { PageCta, TypeFaqTheme } from '@/utils/page';
import {
  FilledContentRelationshipField,
  PrismicDocument
} from '@prismicio/client';
import { nullOrString } from '@proprioo/hokkaido';
import { ButtonTheme } from '@proprioo/salatim';

import {
  ResponseChapterProps,
  ResponseHubProps
} from '../components/guide/utils';

export enum PrismicDocumentType {
  AGENT = 'agent',
  INTERNAL = 'internal-link',
  FAQ = 'faq',
  PAGE = 'page'
}

export enum PrismicDocumentTags {
  CITY = 'ville'
}

export enum TypeHeaderImgDisplay {
  FULL = 'Pleine largeur',
  RIGHT = 'Ferré à droite'
}

export enum TypeColor {
  WHITE = 'blanc',
  GREEN = 'Vert',
  BLUE = 'Bleu',
  ORANGE = 'Orange',
  PINK = 'Rose'
}

export enum TypeLink {
  DOCUMENT = 'Document',
  ANY = 'Any',
  WEB = 'Web',
  MEDIA = 'Media'
}

export enum TypeEmbed {
  VIDEO = 'video',
  SOUND = 'rich'
}

export enum TypeSlice {
  AGENTS = 'agents',
  ARTICLES = 'articles',
  BANNER = 'banniere',
  BENEFITS = 'benefices',
  CASESTUDIES = 'paroles_d_experts',
  CITYDATA = 'info_ville',
  CTA = 'cta',
  EVENT = 'event',
  FAQ = 'faq',
  FAQS = 'toutes_les_faqs',
  HOW = 'how',
  INTRO = 'intro',
  JOBS = 'offres_d_emploi',
  KEY_POINTS = 'key_points',
  LOCATIONS = 'implantations',
  OPINIONS = 'avis_clients',
  PRESS = 'communique_de_presse',
  PARAGRAPH = 'paragraph_article',
  POSTS = 'posts',
  PRICES = 'tarifs',
  PROCESS = 'parcours',
  PROMOTE_CITY = 'seo_ville',
  QUOTE = 'quote',
  REASSURANCE = 'reassurance_agence',
  REASSURANCE_AGENT = 'reassurance',
  RECRUITMENT = 'recrutement',
  REMUNERATION = 'remuneration',
  SOLD = 'biens_vendus',
  SELLERS = 'blocs_vendeurs',
  SIMPLE_TEXT = 'simple_texte',
  TEAMS = 'equipes',
  TEXT_CITATION = 'texte_et_citation',
  TEXT_IMAGE = 'texte_et_image',
  TESTIMONIAL = 'testimonial',
  TESTIMONIES = 'testimoniaux',
  VIDEOS = 'videos'
}

export enum TypeMediaKind {
  DOCUMENT = 'document',
  IMAGE = 'image'
}

// Define order of concerned public in FAQ
export const FaqPublicConcerned = ['Acheteur', 'Vendeur', 'Agent'];

export type PrismicLink = {
  link_type: TypeLink;
  id?: string;
  type?: string;
  slug?: string;
  lang?: string;
  uid?: string;
  url?: string;
  kind?: TypeMediaKind;
  target?: string;
};

export type PrismicCover = RichTextBlock & {
  mobile: RichTextBlock;
};

export type PrismicSlice = {
  slice_type: TypeSlice;
  slice_label: nullOrString;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  primary: any;
};

// YouTube, Vimeo, or Soundcloud
export type PrismicVideo = {
  embed_url: string;
  html: string;
  type: TypeEmbed;
};

export type ArticlePrimaryParagraph = {
  content: RichTextBlock[];
  cta?: TypeCta;
};

export type ArticlePrimaryKeyPoints = {
  content: RichTextBlock[];
};

export type ArticlePrimaryQuote = {
  content: RichTextBlock[];
  agent: string;
};

export type ArticlePrimaryHow = {
  content: RichTextBlock[];
  title_how: RichTextBlock[];
};

export type ArticlePrimaryCta = {
  buttonTitle: string;
  buttonUrl: PrismicLink;
  cardTitle: RichTextBlock[];
  content: RichTextBlock[];
  customType: CtaGuideType;
  theme: ButtonTheme;
  defaultCta?: TypeCta;
};

export type ArticleBodyElement = {
  slice_type: TypeSlice;
  slice_label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  primary:
    | ArticlePrimaryParagraph
    | ArticlePrimaryKeyPoints
    | ArticlePrimaryQuote
    | ArticlePrimaryCta;
};

export type PrismicLinkHub = {
  hub_link: ResponseHubProps;
};

export type PrismicHub = {
  title: RichTextBlock[];
  content?: RichTextBlock[];
  cover?: PrismicCover;
  ctas?: Cta[];
  meta_description?: string;
  meta_title?: string;
};

export type PrismicChapter = {
  title: RichTextBlock[];
  color: string;
  hubs: PrismicLinkHub[];
  content?: RichTextBlock[];
  cover?: PrismicCover;
  ctas?: Cta[];
  meta_description?: string;
  meta_title?: string;
};

export type PrismicArticle = {
  title: RichTextBlock[];
  color: string;
  chapter_link: ResponseChapterProps;
  cover: PrismicCover;
  same_articles: { article_link: PrismicDocument }[];
  body: ArticleBodyElement[];
  meta_description?: string;
  meta_title?: string;
};

export type PrismicPage = {
  header_enabled: boolean;
  header_has_search_field: boolean;
  header_visual: RichTextBlock;
  header_visual_display_mode: TypeHeaderImgDisplay;
  header_bg_color: string;
  header_press_enabled: boolean;
  header_ctas: PageCta[];
  showStickyButton: boolean;
  body: PrismicSlice[];
  title?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: RichTextBlock;
  header_title?: string;
  header_subtitle?: string;
  header_breadcrumb?: string;
  stickyButtonLabel?: string;
  stickyButtonLink?: PrismicLink;
  description?: string;
  phoneNumber?: string;
};

export type PrismicFaq = {
  theme: { data: TypeFaqTheme };
  concerned: { name: string }[];
  content: RichTextBlock[];
  title?: string;
  weight?: number;
};

export type PrismicAgent = {
  fonction: RichTextBlock[];
  imageAgentCropped: RichTextBlock;
  image_agent: RichTextBlock;
  image_dashboard: RichTextBlock;
  listing_sold: nullOrString;
  name: RichTextBlock[];
  quote: nullOrString;
  zone: string;
};

export const hrefResolver = (doc: FilledContentRelationshipField) => {
  const { isBroken, type, tags, uid } = doc;

  if (isBroken) {
    return URLS.ERROR.href;
  }

  if (uid === 'estimation-immobiliere' || uid === 'rendez-vous-estimation') {
    return `/${uid}`;
  }

  if (type === TypeGuide.HUB) {
    return `${URLS.GUIDE.href}/[hub]`;
  }

  if (type === TypeGuide.CHAPTER) {
    return `${URLS.GUIDE.href}/[hub]/[chapter]`;
  }

  if (type === TypeGuide.ARTICLE) {
    return `${URLS.GUIDE.href}/[hub]/[chapter]/[article]`;
  }

  if (type === TypeGuide.PREVIOUS) {
    return `${URL_COMPANY}/${uid}`;
  }

  if (type === PrismicDocumentType.INTERNAL) {
    const slugs = tags.join('/');
    return `/${[slugs, uid].filter(Boolean).join('/')}`;
  }

  // Page
  if (type === PrismicDocumentType.PAGE) {
    if (uid === 'home-idf') {
      return '/';
    }

    if (tags.includes(PrismicDocumentTags.CITY)) {
      return `/ville/[slug]`;
    }

    return `/[slug]`;
  }

  return URLS.SELLER_GUIDE.href;
};

export const linkResolver = (doc: FilledContentRelationshipField) => {
  const { isBroken, uid, tags, type } = doc;

  const slugs = tags.join('/');

  if (isBroken) {
    return URLS.ERROR.href;
  }

  if (type === TypeGuide.HUB) {
    return `${URLS.GUIDE.href}/${uid}`;
  }

  if (type === TypeGuide.CHAPTER || type === TypeGuide.ARTICLE) {
    return `${URLS.GUIDE.href}/${slugs}/${uid}`;
  }

  if (type === TypeGuide.PREVIOUS) {
    return `${URL_COMPANY}/${uid}`;
  }

  if (type === PrismicDocumentType.INTERNAL) {
    return `/${[slugs, uid].filter(Boolean).join('/')}`;
  }

  // Page
  if (type === PrismicDocumentType.PAGE) {
    if (uid === 'home-idf') {
      return '/';
    }

    if (tags.includes(PrismicDocumentTags.CITY)) {
      return `/ville/${uid}`;
    }

    return `/${uid}`;
  }

  return `${URLS.SELLER_GUIDE.as}`;
};

export const isValidLink = (prismicLink?: PrismicLink): boolean => {
  return prismicLink?.link_type !== TypeLink.ANY;
};
