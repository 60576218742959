import styled, { css } from 'styled-components';

import { ButtonsLayout } from '@/components/showcase/homepage/banner/Banner.styles';
import { Grid } from '@/utils/styles';
import {
  breakpoints,
  colors,
  convertPxToRem,
  paragraphs,
  radius,
  shadows,
  titles
} from '@proprioo/salatim';

export const CustomElementsDefault = css`
  margin: ${convertPxToRem(18)} 0 0;

  :first-child {
    margin-top: 0;
  }
`;

export const Layout = styled.div<{ color: string }>`
  background: white;

  ${({ color }) =>
    css`
      border-top: solid ${convertPxToRem(8)} ${color};
    `};

  margin: 0 -${convertPxToRem(16)};
  padding: ${convertPxToRem(32)} ${convertPxToRem(16)};

  @media (min-width: ${breakpoints.xsmall}px) {
    margin: 0 -${convertPxToRem(32)};
    padding: ${convertPxToRem(32)} ${convertPxToRem(32)};
  }

  @media (min-width: ${breakpoints.small}px) {
    margin: 0 -${convertPxToRem(48)};
    padding: ${convertPxToRem(32)} ${convertPxToRem(48)};
  }

  @media (min-width: ${breakpoints.base}px) {
    padding: ${convertPxToRem(60)} ${convertPxToRem(100)} ${convertPxToRem(80)};
    margin: -${convertPxToRem(100)} auto 0;
  }

  @media (min-width: ${breakpoints.normal}px) {
    max-width: 75%;

    ${ButtonsLayout} {
      flex-direction: column;

      a + a {
        margin: ${convertPxToRem(8)} 0 0;
      }
    }
  }
`;

export const ArticleTitle = styled.div`
  margin-bottom: ${convertPxToRem(24)};

  @media (min-width: ${breakpoints.base}px) {
    margin-bottom: ${convertPxToRem(12)};
  }

  h1 {
    ${titles.h2};
    color: ${colors.dark.base};
  }
`;

export const ArticleContent = styled.div`
  > * {
    :first-child {
      margin-top: 0;
    }
  }

  p:empty {
    display: none;
  }

  h2 {
    ${titles.h5};
    margin-top: ${convertPxToRem(56)};
  }

  h3 {
    ${titles.h6};
    font-weight: 600;
    margin-top: ${convertPxToRem(24)};
  }
`;

export const Title = styled.h4`
  ${titles.h4};
  margin-bottom: ${convertPxToRem(24)};

  b {
    color: ${colors.dark.base};
    font-weight: bold;
  }
`;

export const EmbedWrapper = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: ${convertPxToRem(16)};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const CustomOrderedList = styled.ol`
  ${paragraphs.body1};
  ${CustomElementsDefault};
  padding: 0 0 0 ${convertPxToRem(18)};

  a {
    font-weight: bold;
    color: ${colors.terracota.base};
  }
`;

export const CustomList = styled.ul`
  ${paragraphs.body1};
  ${CustomElementsDefault};
  list-style: none;
  padding: 0;

  a {
    font-weight: bold;
    color: ${colors.terracota.base};
  }
`;

export const CustomOrderedListItem = styled.li`
  ${CustomElementsDefault};
  padding-left: ${convertPxToRem(16)};

  & + & {
    margin-top: ${convertPxToRem(8)};
  }
`;

export const CustomListItem = styled.li`
  position: relative;
  padding-left: ${convertPxToRem(32)};

  ::before {
    content: '';
    width: ${convertPxToRem(18)};
    height: ${convertPxToRem(18)};
    border-radius: ${radius.rounded};
    background-color: ${colors.pink.base};
    background-image: url('/static/icons/list-check.svg');
    background-size: 100% auto;
    position: absolute;
    left: 0;
    top: ${convertPxToRem(2)};
  }

  & + & {
    margin-top: ${convertPxToRem(8)};
  }
`;

export const CustomHyperlink = styled.a`
  ${paragraphs.body1};
  font-weight: bold;
  color: ${colors.terracota.base80};
`;

export const CustomParagraph = styled.p`
  ${paragraphs.body1};
  ${CustomElementsDefault};
`;

export const ArticleContainer = styled.div`
  overflow: hidden;
`;

export const ArticleLayout = styled.div`
  ${Grid};
  padding: 0 0 ${convertPxToRem(80)};
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.base}px) {
    margin-top: -${convertPxToRem(80)};
  }
`;

export const ArticleWrapper = styled.div`
  background: white;
  grid-column: 1 / -1;
  padding: ${convertPxToRem(32)} 0 0;

  @media (min-width: ${breakpoints.base}px) {
    box-shadow: ${shadows.level4};
    border-radius: ${radius.normal};
    padding: ${convertPxToRem(40)};
    grid-column: 1 / 9;
  }

  @media (min-width: ${breakpoints.normal}px) {
    padding: ${convertPxToRem(56)};
  }
`;

export const ThemaLayout = styled.div`
  grid-column: 1 / -1;
  padding-top: ${convertPxToRem(56)};

  @media (min-width: ${breakpoints.base}px) {
    grid-column-start: 9;
    padding-top: ${convertPxToRem(120)};
  }
`;

export const ThemaArticles = styled.div`
  position: sticky;
  top: ${convertPxToRem(130)};
`;
