import React, { FC } from 'react';

import CustomLink, { CustomLinkProps } from './CustomLink';
import { Layout } from './CustomLinkStyled.styles';

const CustomLinkStyled: FC<CustomLinkProps> = props => (
  <Layout>
    <CustomLink {...props} />
  </Layout>
);

export default CustomLinkStyled;
