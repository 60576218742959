import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC, Fragment, PropsWithChildren } from 'react';

import { URL_COMPANY } from '@/constants/global';

type PageMetaProps = {
  canonical?: string;
  description?: string;
  socialComponent?: JSX.Element;
  title?: string;
  isNotIndexable?: boolean;
};

const PageMeta: FC<PropsWithChildren<PageMetaProps>> = ({
  canonical,
  description,
  socialComponent,
  title,
  isNotIndexable,
  children
}) => {
  const router = useRouter();
  const [path] = router.asPath.split('?');

  const canonicalUrl = canonical
    ? canonical
    : router && `${URL_COMPANY}${path}`;

  return (
    <Fragment>
      <Head>
        {description && <meta name="description" content={description} />}
        {title && <title>{title}</title>}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {isNotIndexable && <meta name="robots" content="noindex, nofollow" />}
      </Head>
      {socialComponent && <Fragment>{socialComponent}</Fragment>}
      <Fragment>{children}</Fragment>
    </Fragment>
  );
};

export default PageMeta;
